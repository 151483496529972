import { Controller } from '@stimulus/core';

interface WidgetMessengerConfig {
  locale?: string;
  open?: boolean;
  eagerLoad?: boolean;
}

declare global {
  interface Window {
    fcWidgetMessengerConfig: WidgetMessengerConfig;
  }
}
export default class ChatbotWidgetController extends Controller {
  public static targets: any[] = [];

  public connect(): void {
    console.log('ChatWidgetController#connect');
    this.setUserLocale();
  }

  private setUserLocale(): void {
    const locale: string = this.data.get('locale') || document.documentElement.lang;

    window.fcWidgetMessengerConfig = {
      locale,
    };
  }

  private openChatbotWidget(): void {
    window.fcWidgetMessengerConfig = {
      open: true,
    };
  }

  private closeChatbotWidget(): void {
    window.fcWidgetMessengerConfig = {
      open: false,
    };
  }

  private setEagerLoad(): void {
    window.fcWidgetMessengerConfig = {
      eagerLoad: true,
    }
  }

}