import * as $ from "jquery";
import { BehaviorSubject, combineLatest, fromEvent, iif, of, Subscription } from "rxjs";
import { debounceTime, filter, mergeMap, startWith, delay } from "rxjs/operators";
import { ViewportHelper } from '../helpers/viewport-helper';
import { LogHelper } from '../helpers/log-helper';

export interface FormValidationFn {
  (value: string): { type: string, [key: string]: any };
}

export interface FormAsyncValidationFn {
  (value: string): Promise<any>;
}

interface ValidationErrorsMessage {
  errorsPerField?: { 
    [fieldId: string]: any[]
  }
  ignorePristinity?: boolean;
}

export class FormValidationHelper {
  private element: Element;

  private fieldsToValidate: NodeListOf<HTMLElement>;
  private formSubmitted: boolean = false;
  private submitDisabled: boolean = false;
  private validationFunctionsPerField: { [fieldId: string]: Array<FormValidationFn | FormAsyncValidationFn> } = {};
  private validateIfChecked: { [fieldId: string]: HTMLInputElement } = {};

  private validationErrorsSubject: BehaviorSubject<ValidationErrorsMessage> = new BehaviorSubject({});

  private subscriptions: Subscription[] = [];
  /**
   * Connects form validation to parent element of form
   * @param element
   * @returns form validation
   */
  public attachFormValidation(element: Element) {
    // Fetch input fields and submit buttons of form
    if (element.tagName.toLowerCase() === 'form') {
      this.element = element;
    } else {
      this.element = element.getElementsByTagName('form')[0];
    }
    if (!this.element.hasAttribute('data-form-validation-initialized')) {
      this.element.setAttribute('data-form-validation-initialized', 'true');

      this.cleanUpSubscriptions();

      this.fieldsToValidate = this.allFieldsToValidate();

      this.attachSubmitListener();
      this.attachValidationFunctions();

      this.subscriptions.push(
        this.validationErrorsSubject.subscribe(
          this.handleValidationErrors.bind(this)
        )
      );
    }
  }

  /**
   * Sets form errors from backend
   * @param element
   * @param errors
   */
  public setErrorsFromServerResponse(serverErrors: any = {}) {
    this.formSubmitted = false;
    const errorMessageFields = this.allErrorMessageFields();

    const topmostErrorMessageField = errorMessageFields[0];
    if (serverErrors.base && topmostErrorMessageField) {
      topmostErrorMessageField.innerHTML = `<div class="alert alert-danger">${serverErrors.base}</div>`
      topmostErrorMessageField.classList.remove('d-none');
    } else {
      errorMessageFields.forEach(field => field.classList.add('d-none'));
    }

    this.fieldsToValidate.forEach((field: HTMLInputElement) => {
      Object.keys(serverErrors).forEach(key => {
        if (this.serverErrorKeyMatchesField(key, field)) {
          this.addServerErrorMessageToField(field as HTMLInputElement, serverErrors[key]);
          this.setFieldValidationClasses(field, false, false);
        }
      })
    });
    setTimeout(() => {
      this.enableAllInputFields();
      this.disableSubmitButtons();
      this.scrollToFirstError();
    }, 100)
  }

  public hideFormErrors() {
    const messagesFields = this.allErrorMessageFields();
    messagesFields.forEach(field => field.classList.add('d-none'));
  }

  public enableAllInputFields(errors: any = {}) {
    if (Object.keys(errors).length === 0) {
      this.enableSubmitButtons();
      const inputFields = this.findAllInputs();
      inputFields.forEach(field => {
        if (field.hasAttribute('data-form-validation-auto-readonly')) {
          field.removeAttribute('readonly')
          field.removeAttribute('data-form-validation-auto-readonly')
        }
      })
    }
  }

  public disableAllInputFields() {
    this.disableSubmitButtons();

    const inputFields = this.findAllInputs();
    inputFields.forEach(field => {
      field.setAttribute('readonly', 'readonly');
      field.setAttribute('data-form-validation-auto-readonly', 'true');
    });

  }

  public cleanUp() {
    this.cleanUpSubscriptions();
    this.validationFunctionsPerField = {};
    this.element.removeAttribute('data-form-validation-initialized');
  }

  private serverErrorKeyMatchesField(key: string, field: HTMLInputElement) {
    const attribute = key.split('.').splice(-1)[0];
    if (field.dataset.errorKey) {
      return key === field.dataset.errorKey
    } else {
      return (key === field.name || field.name.indexOf(`[${attribute}]`) > -1)
    }
  }

  private enableSubmitButtons() {
    this.submitDisabled = false;
    const buttonFields = this.allSubmitButtons();
    buttonFields.forEach(field => {
      field.removeAttribute('disabled')
      field.classList.remove('disabled');
      field.removeAttribute('data-form-validation-auto-disabled')
    });
  }

  private disableSubmitButtons() {
    this.submitDisabled = true;
    const buttonFields = this.allSubmitButtons();
    buttonFields.forEach(field => {
      if (field.getAttribute('data-form-validate-disable-on-errors') === 'full') {
        field.setAttribute('disabled', 'disabled');
      }
      field.classList.add('disabled');
      field.setAttribute('data-form-validation-auto-disabled', 'true');
    });
  }

  private cleanUpSubscriptions() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private async validateForm(ignorePristinity = false) {
    const errorsPerField = {};
    await Promise.all(Array.from(this.fieldsToValidate).map(async (field) => {
      if (!this.skipValidation(field as HTMLElement)) {
        const htmlElement = this.castElementToHTMLElement(field);
        if (this.validationFunctionsPerField[field.id]) {
          await Promise.all(this.validationFunctionsPerField[field.id].map(async (validation) => {
            const validationError = await validation(htmlElement.value);
            if (validationError !== null) {
              errorsPerField[field.id] = (errorsPerField[field.id] || []).concat([validationError]);
            }
          }));
        }
      }
    }));
    this.validationErrorsSubject.next({ errorsPerField, ignorePristinity });
  }

  private skipValidation(field: HTMLElement) {
    return this.shouldSkipField(field) || this.skipValidationIfNotChecked(field)
  }

  private skipValidationIfNotChecked(field: HTMLElement) {
    return this.validateIfChecked[field.id] &&
      !this.validateIfChecked[field.id].checked &&
      field !== document.activeElement;
  }

  private castElementToHTMLElement(field: Element): HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLElement {
    switch (field.tagName) {
      case 'INPUT': {
        return field as HTMLInputElement;
      }
      case 'TEXTAREA': {
        return field as HTMLTextAreaElement;
      }
      case 'SELECT': {
        return field as HTMLSelectElement;
      }
      default: {
        return field as HTMLElement;
      }
    }
  }

  private addFieldValidationObserver(field: Element) {
    const id = field.id;
    if (field['eventObs']) {
      field['eventObs'].unsubscribe();
    }

    const htmlElement = this.castElementToHTMLElement(field);

    if (this.validationFunctionsPerField[id]) {
      if (!htmlElement.hasAttribute('form-pristine-value')) {
        htmlElement.setAttribute('form-pristine-value', `${htmlElement.value}`);
      }

      this.subscriptions.push(
        fromEvent(htmlElement, 'focus').subscribe(() => {
          htmlElement.setAttribute('data-form-focused', 'true');
        })
      );
      this.subscriptions.push(combineLatest(
        fromEvent(htmlElement, 'keyup').pipe(
          startWith({
            code: '',
            target: htmlElement,
            focus: false
          }),
          filter((event: KeyboardEvent) => event.code !== 'Tab')
        ),
        fromEvent(htmlElement, 'blur').pipe(startWith(null)),
        fromEvent(htmlElement, 'change').pipe(startWith(null)),
      ).pipe(
        mergeMap(v => {
          return iif(() => !this.formSubmitted, of(v), of([]));
        }),
        debounceTime(40)
      ).subscribe(events => {
        const blurEvent = events[1];
        if (blurEvent) {
          htmlElement.removeAttribute('data-form-focused');
          htmlElement.setAttribute('data-form-blurred', 'true');
        }
        if (!this.shouldSkipField(field)) {
          htmlElement.removeAttribute('data-form-validation-has-backend-error');

        }
        this.validateForm();
      }));
    }
  }

  private shouldSkipField(field: Element) {
    return $(field).is(':hidden') ||
      $(field).attr('disabled') ||
      $(field).attr('data-form-no-feedback');
  }

  private setFieldValidationClasses(field: Element, valid: boolean, isPristine: boolean) {
    if (!this.shouldSkipField(field) && !$(field).attr('data-form-focused')) {
      field.classList.toggle('is-valid', valid && !isPristine && !field.hasAttribute('data-form-validation-has-backend-error'));
      field.classList.toggle('is-invalid', (!valid && !isPristine) || field.hasAttribute('data-form-validation-has-backend-error'));
      field.classList.toggle('is-pristine', isPristine);
      field.classList.toggle('is-dirty', !isPristine);
    }
  }

  private showErrorMessageToField(field: HTMLElement, messages: string[]) {
    $(field).nextAll('.invalid-feedback').find('div').addClass('d-none');

    messages.filter(m => m !== null).forEach(message => {
      $(field).nextAll('.invalid-feedback').find(`div[data-error-type='${message}']`).removeClass('d-none');
    });
  }

  private addServerErrorMessageToField(field: HTMLElement, messages: string[]) {
    field.removeAttribute('data-form-validation-has-backend-error');

    if (!field.dataset.noFeedbackMessage) {
      $(field).nextAll('.invalid-feedback').find('div').addClass('d-none');
      let $errorMessageDiv = $(field).next('.invalid-feedback');
      if ($errorMessageDiv.length === 0) {
        $errorMessageDiv = $('<div class="invalid-feedback"></div>').insertAfter($(field));
      }

      $errorMessageDiv.find(`div[data-error-type='backend']`).remove();
      messages.filter(m => m !== null).forEach(message => {
        $errorMessageDiv.append(`<div data-error-type='backend'>${message}</div>`);
      });
    }

    if (messages.length > 0) {
      field.setAttribute('data-form-validation-has-backend-error', 'true');
      this.setFieldValidationClasses(field as Element, false, false);
    }
  }

  private allFieldsToValidate() {
    return this.element.querySelectorAll(`[data-form-validate]`) as NodeListOf<HTMLElement>;
  }

  private allSubmitButtons() {
    return this.element.querySelectorAll(`input[data-form-validate-allow-disable],button[data-form-validate-allow-disable],input[type="submit"]`);
  }

  private findAllInputs() {
    return this.element.querySelectorAll(`input,select,radio,textarea`);
  }

  private allErrorMessageFields() {
    return this.element.querySelectorAll(`[data-form-validate-messages]`);
  }

  private attachSubmitListener() {
    const formElement = (this.element.tagName === 'FORM') ? this.element : this.element.querySelector('form');

    this.subscriptions.push(fromEvent(formElement, 'submit', { capture: true }).subscribe(e => {
      if (this.submitDisabled) { 
        e.preventDefault()
      } else {
        this.formSubmitted = true;
      }
    }))
    this.subscriptions.push(
      combineLatest(
        Array.from(this.allSubmitButtons()).map(b => fromEvent(b, 'click', { capture: true }))
      ).subscribe(() => {
        if (this.submitDisabled) { this.validateForm(true); }
      })
    )
  }

  private attachValidationFunctions() {
    this.fieldsToValidate.forEach(field => {
      const fieldId = field.id;
      this.validationFunctionsPerField[fieldId] = [];
      if (field.dataset.formValidateIfChecked) {
        this.addValidationCondition(field, field.dataset.formValidateIfChecked);
      }
      const validationTypes: string[] = field.getAttribute('data-form-validate').split(',')

      validationTypes.forEach(value => {
        const [type, option] = value.split(':');
        const options = option ? { [option]: true } : {};

        switch (type) {
          case 'email': {
            this.validationFunctionsPerField[fieldId].push(FormValidationMethods.EMAIL(options));
            break;
          }
          case 'phonenumber': {
            this.validationFunctionsPerField[fieldId].push(FormValidationMethods.PHONENUMBER(options));
            break;
          }
          case 'positive_integer': {
            this.validationFunctionsPerField[fieldId].push(FormValidationMethods.POSITIVE_INTEGER(options));
            break;
          }
          case 'minLength': {
            const length = parseInt(field.getAttribute('data-form-validate-arg'));
            this.validationFunctionsPerField[fieldId].push(FormValidationMethods.MINLENGTH(length, options));
            break;
          }
          case 'maxLength': {
            const length = parseInt(field.getAttribute('data-form-validate-arg'));
            this.validationFunctionsPerField[fieldId].push(FormValidationMethods.MAXLENGTH(length, options));
            break;
          }
          case 'fixedLength': {
            const length = parseInt(field.getAttribute('data-form-validate-arg'));
            this.validationFunctionsPerField[fieldId].push(FormValidationMethods.FIXEDLENGTH(length, options));
            break;
          }
          case 'password': {
            this.validationFunctionsPerField[fieldId].push(FormValidationMethods.PASSWORD(options));
            break;
          }
          case 'pattern': {
            const regexpString = field.getAttribute('data-form-validate-arg');
            if (regexpString.startsWith('{') && regexpString.endsWith('}')) {
              try {
                const relatedFieldName = field.getAttribute('data-form-validate-related-field');
                const relatedField = Array.from(this.fieldsToValidate).find((field) => field.getAttribute('data-form-validate-id') === relatedFieldName || field.getAttribute('name') === relatedFieldName)
                const regexpObject = JSON.parse(regexpString);
                if (relatedField && regexpObject[(relatedField as HTMLInputElement).value]) {
                  this.validationFunctionsPerField[fieldId].push(FormValidationMethods.RELATED_PATTERN(relatedField, regexpObject, options));
                }
              } catch (e) {
                LogHelper.logError(e)
              }
            } else {
              const pattern = new RegExp(regexpString);
              this.validationFunctionsPerField[fieldId].push(FormValidationMethods.PATTERN(pattern, options));
            }
            break;
          }
          case 'required': {
            this.validationFunctionsPerField[fieldId].push(FormValidationMethods.REQUIRED(options));
            break;
          }
          default: { }
        }
      });

      this.addFieldValidationObserver(field);
    });
  }

  private addValidationCondition(field: HTMLElement, checkBoxName: string) {
    const checkBox = document.getElementById(checkBoxName) as HTMLInputElement;
    this.validateIfChecked[field.id] = checkBox;
    this.subscriptions.push(
      fromEvent(checkBox, 'change').pipe(delay(40)).subscribe(() => { // added delay to let checkbox reset states
        this.validateForm()
      })
    )
  }

  private handleValidationErrors({ errorsPerField, ignorePristinity }) {
    if (errorsPerField == null) {
      return
    }
    const fieldsWithErrorIds = Object.keys(errorsPerField);

    this.fieldsToValidate.forEach(field => {
      const htmlElement = this.castElementToHTMLElement(field);
      const isPristine = !htmlElement.hasAttribute('data-form-blurred') && `${htmlElement.value}` === (htmlElement.getAttribute('form-pristine-value') || '');
      const errors = errorsPerField[htmlElement.id];
      if (errors && !errors.every(e => e.onlyOnSubmit)) {
        const setPristine = isPristine && !ignorePristinity;
        this.setFieldValidationClasses(htmlElement, false, setPristine);
        this.showErrorMessageToField(htmlElement, errors.map(e => e.type, true));
      } else {
        this.setFieldValidationClasses(htmlElement, true, isPristine);
      }
    })
    if (!fieldsWithErrorIds.length) {
      this.enableSubmitButtons();
    } else {
      this.disableSubmitButtons();
    }
  }

  private scrollToFirstError() {
    const invalidFields = this.element.querySelectorAll('.is-invalid');
    if (invalidFields.length > 0) {
      const firstElement = invalidFields.item(0);
      ViewportHelper.scrollIntoViewIfNeeded(firstElement);
    }
  }
}

export class FormValidationMethods {
  public static EMAIL(options) {
    return (email: string) => {
      // Allow an empty email field; if not allowed, that should be guarded by an additional 'required' validation
      if (email.trim().length === 0) { return null }
      if (RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)) {
        return null;
      }
      return { type: 'invalid', ...options };
    }
  }

  public static PHONENUMBER(options) {
    return (phonenumber: string) => {
      // Allow an empty phonenumber field; if not allowed, that should be guarded by an additional 'required' validation
      if (phonenumber.trim().length === 0) { return null }
      if (RegExp(/(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/).test(phonenumber)) {
        return null;
      }
      return { type: 'invalid', ...options };
    }
  }

  public static POSITIVE_INTEGER(options) {
    return (value: string) => {
      if (value.trim().length === 0) { return null }
      const num = parseFloat(value);
      if (Number.isInteger(num) && num > 0) {
        return null;
      }
      return { type: 'positive_integer', ...options };
    }
  }

  public static MINLENGTH(length, options) {
    return (value: string) => {
      if (value.trim().length >= length) {
        return null;
      }
      return { type: 'too_short', length: value.trim().length, minLengthRequired: length, ...options };
    }
  }

  public static MAXLENGTH(length, options) {
    return (value: string) => {
      if (value.trim().length <= length) {
        return null;
      }
      return { type: 'too_long', length: value.trim().length, maxLengthRequired: length, ...options };
    }
  }

  public static FIXEDLENGTH(length, options) {
    return (value: string) => {
      if (value.trim().length === length) {
        return null;
      }
      return { type: 'invalid', length: value.trim().length, lengthRequired: length, ...options };
    }
  }

  public static REQUIRED(options) {
    return (value: any) => {
      if (typeof value === 'string') {
        if (value.trim().length > 0) {
          return null;
        }
      } else if (value !== null || value !== undefined) {
        return null;
      }
      return { type: 'blank', ...options };
    }
  }

  public static PATTERN(pattern: RegExp, options) {
    return (value: string) => {
      if (pattern.test(value)) {
        return null;
      }
      return { type: 'invalid', ...options };
    }
  }

  public static RELATED_PATTERN(relatedField: Element, patterns: any, options) {
    return (value: string) => {
      if (new RegExp(patterns[(relatedField as HTMLInputElement).value]).test(value)) {
        return null;
      }
      return { type: 'invalid', ...options };
    }
  }

  public static PASSWORD(options) {
    return (value: string) => {
      if (/.{8,}/.test(value)) {
        return null;
      }
      return { type: 'invalid', ...options };
    }
  }
}
