import { Controller } from '@stimulus/core';
import { CombineSubscriptions, DestroySubscribers } from "ngx-destroy-subscribers";
import { ResponsiveHelper } from '../helpers/responsive-helper';
import * as $ from 'jquery';
import { Unsubscribable, fromEvent } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';

const HIDE_HEADER_MIN_SCROLL_DOWN = 82;
const SCROLL_THRESHOLD_PCT = 2;

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class HeaderController extends Controller {
  public static targets = ['navSubCollapse'];

  public element: HTMLElement;

  private readonly navSubCollapseTarget?: HTMLElement;
  private readonly hasNavSubCollapseTarget: boolean;
  private lastScrollY: number = 0;

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  public connect() {
    this.lastScrollY = window.scrollY;

    this.scheduleScrollListener();
    this.addClickListener();

    this.subscriber = fromEvent(window, 'load').subscribe(() => {
      this.subscriber = fromEvent(window, 'resize').pipe(
        startWith(null),
        debounceTime(40),
      ).subscribe(() => this.updateNavWidthSSVar());
    });
  }

  private scheduleScrollListener() {
    // Prevent scrollRestoration from triggering the show/hide header behaviour
    this.subscriber = fromEvent(window, 'scroll').pipe(
      debounceTime(1000),
    ).subscribe(() => this.onScroll());
  }

  private onScroll() {
    if (this.shouldHideHeaderOnScroll) {
      if (!this.negativeScrollY && this.scrolledPastThreshold) {
        const scrollDirection = this.calcScrollDirection();
        if (this.isNavBarVisible && scrollDirection === 'down' && this.scrolledPastTopOfPage) {
          this.hideNavBar();
        } else if (!this.isNavBarVisible && scrollDirection === 'up') {
          this.showNavBar();
        }
      }
    } else {
      this.showNavBar();
    }
  };

  private calcScrollDirection(): 'down' | 'up' {
    const dir = (window.scrollY > this.lastScrollY) ? 'down' : 'up';
    this.lastScrollY = window.scrollY;
    return dir;
  }

  private showNavBar() {
    document.body.classList.remove('nav-hide');
  }

  private hideNavBar() {
    document.body.classList.add('nav-hide');
  }

  private get isNavBarVisible() {
    return !document.body.classList.contains('nav-hide');
  }

  private get shouldHideHeaderOnScroll() {
    return ResponsiveHelper.isBreakpointBelow('md');
  }

  private get negativeScrollY() {
    return window.scrollY < 0;
  }

  private get scrolledPastThreshold() {
    const scrollThreshold = (window.innerHeight * SCROLL_THRESHOLD_PCT / 100);
    return Math.abs(window.scrollY - this.lastScrollY) > scrollThreshold;
  }

  private get scrolledPastTopOfPage() {
    return window.scrollY > HIDE_HEADER_MIN_SCROLL_DOWN;
  }

  private addClickListener(): void {
    document.addEventListener('click', (event: MouseEvent) => {
      if (this.hasNavSubCollapseTarget && !this.navSubCollapseTarget.contains(event.target as Node)) {
        $(this.navSubCollapseTarget).collapse('hide');
      }
    })
  }

  private updateNavWidthSSVar() {
    const navLogoElement = document.querySelector('.nav-logo');
    const navMainElement = document.querySelector('.nav-main .main-nav a');
    const navSubElement = document.querySelector('.nav-sub');

    if (navLogoElement && navMainElement && navSubElement) {
      const navLogoWidth = navLogoElement.getBoundingClientRect().width;
      let navMainWidth = navMainElement.getBoundingClientRect().width;
      let navSubWidth = navSubElement.getBoundingClientRect().width;

      if (ResponsiveHelper.isBreakpointAtLeast('xxl')) {
        if ((navLogoWidth + navMainWidth) > navSubWidth) {
          navSubWidth = navLogoWidth + navMainWidth;
        } else {
          navMainWidth = navSubWidth - navLogoWidth;
        }
      }

      document.documentElement.style.setProperty('--nav-main-width', `${navMainWidth}px`);
      document.documentElement.style.setProperty('--nav-sub-width', `${navSubWidth}px`);
    }

  }


}
