import * as $ from 'jquery';
import { CombineSubscriptions, DestroySubscribers } from "ngx-destroy-subscribers";
import { Unsubscribable, combineLatest, fromEvent } from 'rxjs';
import { Container } from 'typedi';
import { Controller } from '@stimulus/core';
import { ModalHelper } from '../helpers/modal-helper';
import { secureFetch } from '../auth/secure_fetch_function';
import { SessionSubject, SessionToken } from '../subjects/session_subject';
import { debounceTime, filter } from 'rxjs/operators';
import { LogHelper } from '../helpers/log-helper';

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class BodyController extends Controller {
  public static targets = ['userName', 'newsletter'];
  public element: HTMLElement;

  private readonly userNameTargets: HTMLElement[];
  private readonly newsletterTarget: HTMLElement;
  private readonly hasNewsletterTarget: boolean;

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  public initialize() {
    this.broadcastSessionData();
    this.createSessionSubscriber();
    ModalHelper.checkModalURL();
  }

  public connect() {
    this.setupBootstrapTooltips();
    this.setupBootstrapAlerts();
    if (this.isIOSPlatform) {
      this.trackIOSVirtualHeight();
      this.trackIOSVisualViewportHeight();
    }
    if (!this.isTouchScreen) {
      this.initSetScrollBarWidth();
    }
  }

  public openModal(event) {
    const url = event.currentTarget.dataset.url;
    if (url) {
      ModalHelper.openModalWithUrl(url);
    }
  }

  public async openSessionModal(event) {
    const url = event.currentTarget.dataset.url;
    const session = await this.refreshSession();
    if (!session?.user) {
      ModalHelper.openModalWithUrl(url);
    }
  }

  private setupBootstrapTooltips() {
    $('[data-toggle="tooltip"]').tooltip({
      delay: this.isIOSPlatform ? { "show": 600, "hide": 0 } : 0
    });
  }

  private setupBootstrapAlerts() {
    $('.alert').alert();
  }

  private trackIOSVirtualHeight() {
    // On iOS, the vheight may change on scroll because of the address bar autohiding
    this.subscriber = fromEvent(window, 'resize').pipe(
      debounceTime(40),
    ).subscribe(() => this.setCssVirtualHeightProperty());
    this.setCssVirtualHeightProperty();
  }

  private trackIOSVisualViewportHeight() {
    // On iOS, the visual viewport height may change when the keyboard shows/hides
    window.visualViewport.addEventListener('resize', () => this.setCssViewportHeightProperties());
    this.setCssViewportHeightProperties();
  }

  private setCssVirtualHeightProperty() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  private setCssViewportHeightProperties() {
    let visualViewportHeight = Math.round(window.visualViewport.height);
    let viewportHeight = Math.round(window.innerHeight);
    document.documentElement.style.setProperty('--visual-viewport-height', `${visualViewportHeight}px`);
    document.documentElement.style.setProperty('--viewport-height', `${viewportHeight}px`);

    if (viewportHeight > visualViewportHeight ) {
      document.body.classList.add('vvp-active');
    } else {
      document.body.classList.remove('vvp-active');
    }
  }

  get isIOSPlatform() {
    return (navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform))
      || (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform));
  }

  get isTouchScreen() {
    if ('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0) {
      return true;
    }

    if ('msMaxTouchPoints' in navigator && navigator.msMaxTouchPoints > 0) {
      return true;
    }

    const mQ = window.matchMedia && matchMedia("(pointer: coarse)");
    if (mQ && mQ.media === "(pointer: coarse)") {
      return !!mQ.matches;
    }

    if ('ontouchstart' in window || (window.DocumentTouch && document instanceof window.DocumentTouch)) {
      return true;
    }

    return false;
  }

  private createSessionSubscriber() {
    this.subscriber = Container.get(SessionToken).asObservable().subscribe(session => {
      this.element.dataset.signedIn = (!!(session && session.user)).toString();
      if (session) {
        if (session.user) {
          if (this.hasNewsletterTarget) {
            this.newsletterTarget.classList.remove('show');
          }
          if (session.user.next_registration_step.length !== 0) {
            this.element.dataset.nextRegistrationStep = session.user.next_registration_step;
          } else {
            delete this.element.dataset.nextRegistrationStep;
          }
          this.element.dataset.accountType = session.user.account_type;
          this.userNameTargets.forEach(element => element.innerHTML = session.user_menu_title);
        } else {
          this.element.dataset.nextRegistrationStep = 'sign_up_sign_in';
        }
        if (session.csrf_token) {
          document.querySelector("meta[name=csrf-token]").setAttribute('content', session.csrf_token);
        }
      }
    })
  }

  private setCssVhProperty() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    if (window.visualViewport) {
      const vh = window.visualViewport.height.toString();
      document.documentElement.style.setProperty('--visual-viewport-height', `${vh}px`);
    }
  }

  private initSetScrollBarWidth() {
    if (document.documentElement.style.getPropertyValue('--scrollbar-width') !== '') {
      return
    }
    const div = document.createElement('div');
    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';
    div.style.visibility = 'hidden';
    document.body.appendChild(div);
    document.documentElement.style.setProperty('--scrollbar-width', `${div.offsetWidth - div.clientWidth}px`);
    document.body.removeChild(div);
  }

  private async refreshSession() {
    const response = await secureFetch('/session');
    if (response.ok) {
      const json = await response.json();
      Container.get(SessionToken).next(json.session);
      return json.session;
    }
    return null;
  }

  private broadcastSessionData() {
    try {
      const sessionData = this.element.getAttribute('data-session');
      const session = sessionData ? JSON.parse(sessionData).session : null;
      Container.set(SessionToken, new SessionSubject(session));
    } catch (e) {
      LogHelper.logError(e);
    }
  }
}