// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Workaround to prevent plugins loading different jQuery instances
window.$ = window.jQuery = require('jquery')

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../stylesheets/application';

// Copies all static images under ../images to the output folder. 
// Reference them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper:
// const imagePath = (name) => images(name, true)
require.context('../images', true)

require('@honeybadger-io/js').configure({
  apiKey: document.head.querySelector('meta[property="honeybadger.apiKey"]').content,
  environment: document.head.querySelector('meta[property="honeybadger.env"]').content,
  reportData: true,
  revision: document.head.querySelector('meta[property="honeybadger.rev"]').content,
});
require('@rails/ujs').start()
import 'turbolinks-push-gtm-virtual-page-load-event'
require('turbolinks').start()

import application from "controllers";
import { setupStimulusErrorHandler } from 'error-handler';
setupStimulusErrorHandler(application);

import { addTurboLinksCleanUpEvent } from 'turbolinks-cleanup';
addTurboLinksCleanUpEvent(application);

import 'local-time.config'

import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/tooltip'
import 'bootstrap/js/dist/button'
import 'bootstrap/js/dist/alert'
import 'bootstrap/js/dist/toast'
