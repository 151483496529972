import { Controller } from '@stimulus/core';
import { ServerResponseHelper } from '../helpers/server-response-helper';
import { LogHelper } from '../helpers/log-helper';
import { EventHelper } from '../helpers/event-helper';
import { secureFetch } from '../auth/secure_fetch_function';

declare var AdyenCheckout:any;

export default class AdyenPaymentController extends Controller {
  static targets = ['dropin'];

  private dropinTarget: HTMLDivElement;
  private checkout;
  private dropin;

  public connect() {
    const script = document.createElement("script");
    script.src = "https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/5.53.2/adyen.js";
    script.integrity = "sha384-ng3HLoZIlQ3BLgyGyGNiwWSx6LEPIlmxVuGRw72skZFt9mL8OweRjp7vcPzSqxTj";
    script.crossOrigin = "anonymous";
    script.onload = this.initAdyenCheckout.bind(this); // Wait until the script is loaded before initiating AdyenCheckout
    document.body.appendChild(script);
  }

  private async initAdyenCheckout() {
    await this.initCheckout();
    this.initDropin();
  }

  private async initCheckout() {
    const paymentMethodDetails = JSON.parse(this.data.get('paymentMethodDetails'));
    const clientKey = this.data.get('clientKey');

    const vipioStyleObject = {
      base: {
        color: '#4A154B',
        fontSize: '16px',
        fontFamily: 'sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial'
      },
      error: {
        color: '#dc3545'
      },
      placeholder: {
        color: '#b8a4b9'
      },
      validated: {
        color: '#4A154B'
      }
    };

    const configuration = {
      locale: this.data.get('locale'),
      environment: this.data.get('environment'),
      paymentMethodsResponse: paymentMethodDetails,
      onSubmit: this.onSubmit.bind(this),
      onAdditionalDetails: this.onAdditionalDetails.bind(this),
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          enableStoreDetails: true,
          name: 'Credit card',
          styles: vipioStyleObject,
        },
        applepay: {
          amount: {
            value: this.data.get('amountCents'),
            currency: "EUR",
          },
          countryCode: "NL",
        },
        ideal: {
          showImage: true,
        },
        googlepay: {
          amount: {
            currency: "EUR",
            value: this.data.get('amountCents'),
          },
          buttonType: "book",
          countryCode: "NL",
          environment: this.data.get('environment') === 'test' ? "TEST" : "PRODUCTION",
          gatewayMerchantId: this.data.get('googlePayGatewayMerchantId'),
          merchantId: this.data.get('googlePayMerchantId'),
          merchantName: "Vipio",
        },
        bcmc: {
          styles: vipioStyleObject,
        }
      },
      clientKey
    };

    this.checkout = await AdyenCheckout(configuration);
  }

  private initDropin() {
    this.dropin = this.checkout.create('dropin', {
      openFirstPaymentMethod: false
    }).mount(this.dropinTarget);
  }

  private onSubmit(state, component) {
    this.setPaymentButtonsDisabled(true);
    this.handleSubmission(state, component, this.data.get('payments_url'));
    EventHelper.dispatch(this.element, 'data-layer-rcv-payment-started', {});
  }

  private onAdditionalDetails(state, component) {
    this.handleSubmission(state, component, this.data.get('additional_details_url'));
  }

  // Event handlers called when the shopper selects the pay button,
  // or when additional information is required to complete the payment
  private handleSubmission(state, component, url) {
    if (state.isValid) {
      this.callServer(url, state.data)
        .then(res => this.handleServerResponse(res, component))
        .catch(error => {
          LogHelper.logError(error);
          ServerResponseHelper.internalErrorMessageAlert();
          this.setPaymentButtonsDisabled(false);
        });
    }
  }

  // Handles responses sent from your server to the client
  private handleServerResponse(res, component) {
    if (res.alert) {
      alert(res.alert)
    }
    if (res.redirect) {
      window.location.href = res.redirect.url;
    } else if (res.action) {
      component.handleAction(res.action);
    } else {
      ServerResponseHelper.internalErrorMessageAlert();
      this.setPaymentButtonsDisabled(false);
    }
  }

  // Calls your server endpoints
  private callServer(url, data) {
    return secureFetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json();
    });
  }

  private setPaymentButtonsDisabled(isDisabled: boolean) {
    this.element.querySelectorAll('.adyen-checkout__button--pay')
      .forEach(el => (el as HTMLButtonElement).disabled = isDisabled);
  }
}
